import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["startDate", "numberOfDays", "completedDate", "completionTime", "estimatedDate", "numberOfBusinessDays"];

  calculatedEndDate() {
    let currentDate = this.startDateTarget.value;
    if (!this.hasNumberOfDaysTarget || !this.isDate(currentDate)) {
      return
    }
    let numberOfDays = this.numberOfDaysTarget.value;
    fetch(`/calculated_completion_date.json?start_date=${currentDate}&number_of_days=${numberOfDays}`)
      .then(response => response.json())
      .then((data) => {
        this.completedDateTarget.innerHTML = data.completion_date
      });
  }

  numberOfDays() {
    if (!this.hasEstimatedDateTarget) {
      return
    }

    const startDate = new Date(this.startDateTarget.value);
    const endDate = new Date(this.estimatedDateTarget.value);

    if (!this.isDate(startDate) || !this.isDate(endDate)) {
      return
    }

    fetch(`/calculated_number_of_days.json?start_date=${startDate}&end_date=${endDate}`)
      .then(response => response.json())
      .then((data) => {
        this.completionTimeTarget.value = data.number_of_days
        this.numberOfBusinessDaysTarget.innerHTML = data.number_of_days
      });
  }

  isDate(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }
}
