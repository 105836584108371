import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  checkZipcode(event) {
    const zipcode = event.target.value;
    let url = event.target.dataset.url || `/configuration/zipcodes/:code/check`;
    if (zipcode.length !== 5) {
      return;
    }

    get(url.replace(":code", zipcode), { responseKind: "turbo-stream" });
  }
}
