import { Controller } from "stimulus"
import { handleRequiredFields } from "./concerns/handle_required_fields";

export default class extends Controller {
  static targets =["hiddenFields"]

  toggle(event) {
    const $target = this.hiddenFieldsTargets.find((el) => el.dataset.for === event.target.value)
    if (!$target) return;

    $target.classList.toggle('hidden', !event.target.checked);

    if ($target.dataset.cleanable === 'true') {
      this.cleanTargetValue($target);
    } else {
      handleRequiredFields($target);
    }
  }

  toggleReverse(event) {
    const $target = this.hiddenFieldsTargets.find((el) => el.dataset.for === event.target.value)
    if (!$target) return;

    $target.classList.toggle('hidden', event.target.checked);

    handleRequiredFields($target);
  }

  cleanTargetValue(elem) {
    if (elem.classList.contains('hidden')) {
      elem.removeAttribute('required');
      elem.value = '';
    } else {
      elem.setAttribute('required', true);
    }
  }


  connect() {
    Array.from(this.hiddenFieldsTargets).forEach(handleRequiredFields);
  }
}
